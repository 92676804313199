import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import AudioClick from "@audio/select-click.wav";
import { apiCaller } from "@utils";
import { useScreenSize } from "@context/ScreenSizeContext";

const MicRecordWrapper = styled.div`
  display: flex;
  width: ${(props) => (props.isMobile ? "32px" : "64px")};
  height: ${(props) => (props.isMobile ? "32px" : "64px")};
  padding: ${(props) => (props.isMobile ? "0" : "8px 20px")};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: ${(props) => (props.isMobile ? "none" : "1px solid #e3e8ef")};
  background: #fcfcfd;
  box-shadow: ${(props) => (props.isMobile ? "none" : "0px 1px 2px 0px rgba(16, 24, 40, 0.05)")};
  cursor: pointer;
  position: relative;
  &:hover svg {
    transform: ${(props) => (props.recording || props.isMobile ? "none" : "scale(1.1)")};
    transition: transform 0.2s ease-in-out;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
`;

const GlowEffect = styled.div`
  position: absolute;
  width: ${(props) => (props.isMobile ? "36px" : "44px")};
  height: ${(props) => (props.isMobile ? "36px" : "44px")};
  border-radius: 50%;
  background-color: rgba(34, 104, 240, 0.5);
  animation: ${pulse} 1.5s infinite;
  z-index: 0;
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 1;

  svg {
    width: ${(props) => (props.isMobile ? "32px" : "48px")};
    height: ${(props) => (props.isMobile ? "32px" : "48px")};
  }
`;

function MicRecord({ handleSubmitFromSTT, onStreamUpdate, onRecordingStatusChange }) {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const { isMobile } = useScreenSize();

  const handleSubmit = async (audioBlob) => {
    const formData = new FormData();
    formData.append("audio_file", audioBlob, "recording.mp3");
    try {
      const response = await apiCaller("/api/binh/stt/", "POST", formData);
      if (response?.status === 200 && response?.data?.text) {
        const stringSTT = response.data.text;
        if (typeof handleSubmitFromSTT === "function") {
          await handleSubmitFromSTT(stringSTT);
        }
      } else {
        console.error("API returned unexpected response:", response);
      }
    } catch (error) {
      console.error("Error during API submission:", error);
    }
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      onStreamUpdate?.(stream);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      audioChunksRef.current = [];
      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
        await handleSubmit(audioBlob);

        onStreamUpdate?.(null);
        onRecordingStatusChange?.(false);
      };

      mediaRecorder.start();
      setRecording(true);
      onRecordingStatusChange?.(true);
    } catch (err) {
      if (err.name === "NotAllowedError") {
        alert("Microphone access is required for recording.");
      } else {
        console.error("Error starting recording:", err);
      }
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      setRecording(false);
    }
  };

  const handleClick = () => {
    const audio = new Audio(AudioClick);
    audio.play();

    if (recording) {
      handleStopRecording();
    } else {
      handleStartRecording();
    }
  };

  return (
    <MicRecordWrapper
      onClick={handleClick}
      recording={recording}
      isMobile={isMobile}
      title={recording ? "Stop Recording" : "Start Recording"}
    >
      {recording ? (
        <>
          <GlowEffect isMobile={isMobile} /> {/* Hiệu ứng loang phía sau */}
          <IconWrapper isMobile={isMobile}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
              <circle cx="24" cy="24" r="24" fill="#7CAEE9" fillOpacity="0.34" />
              <circle cx="23.7527" cy="24.2463" r="17.3196" fill="#2268F0" />
              <rect x="17" y="17" width="13" height="13" rx="4" fill="white" />
            </svg>
          </IconWrapper>
        </>
      ) : (
        <IconWrapper isMobile={isMobile}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
            <circle cx="24" cy="24" r="24" fill="#7CAEE9" fillOpacity="0.34" />
            <circle cx="23.7527" cy="24.2463" r="17.3196" fill="#2268F0" />
            <rect x="21.0874" y="15.3364" width="5.8424" height="9.49391" rx="2.9212" fill="white" />
            <rect x="22.3652" y="29.9424" width="2.9212" height="2.9212" rx="1.4606" fill="white" />
            <path
              d="M29.5774 21.9087C29.5774 25.1353 26.9617 27.7511 23.735 27.7511C20.5084 27.7511 17.8926 25.1353 17.8926 21.9087"
              stroke="white"
              strokeWidth="1.59753"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconWrapper>
      )}
    </MicRecordWrapper>
  );
}

export default MicRecord;