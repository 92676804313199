import React from 'react';
import styled from 'styled-components';

const MedicalDescriptionWrapper = styled.div`
  width: 100%;
`;

const TabContent = styled.div`
  border-radius: 0 0 12px 12px;
`;

const MedicalDescription = styled.div`
  border-radius: 8px;
`;

const MedicalDescriptionContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  overflow: auto;
  color: #081B2A;
`;

const MedicalDescriptionBox = ({ narratives = [], activeTab }) => {
  return (
    <MedicalDescriptionWrapper>
      {narratives?.map(
        (narrative) =>
          activeTab === narrative.TimePointId && (
            <TabContent key={narrative.TimePointId}>
              <MedicalDescription>
                <MedicalDescriptionContent dangerouslySetInnerHTML={{ __html: narrative.NarrativeText }} />
              </MedicalDescription>
            </TabContent>
          )
      )}
    </MedicalDescriptionWrapper>
  );
};

export default MedicalDescriptionBox;