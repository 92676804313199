import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useScreenSize } from "@context/ScreenSizeContext";
import styled from "styled-components";
import { MicRecord, ButtonSend, TextArea, CustomIcon, AudioWaveform } from "../ChatFooter";

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? "0" : "8px")};
  padding: ${({ isMobile }) => (isMobile ? "0" : "16px 20px")};
  border-radius: ${({ isMobile, typeQuestion }) =>
    typeQuestion ? "none" : isMobile ? "8px 8px 0 0" : "8px"};
  border: ${({ isMobile, typeQuestion }) =>
    typeQuestion ? "none" : isMobile ? "none" : "1px solid #e3e8ef"};
  background: #fff;
  box-shadow: ${({ isMobile }) =>
    isMobile ? "none" : "0 1px 2px rgba(16, 24, 40, 0.05)"};
  flex: ${({ isMobile }) => (isMobile ? "1 0 0" : "unset")};
  position: ${({ isMobile }) => (isMobile ? "relative" : "unset")};
  bottom: ${({ isMobile }) => (isMobile ? "10px" : "unset")};
`;

const StyledTextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledAudioWaveform = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ isMobile }) => (isMobile ? "12px" : "0")};
`;

function InputFreeText({
  handleSubmitQuestion,
  isLoading = false,
  valueOptions,
  isMessageLoader = false,
  disabled = false,
  textareaRef,
  typeQuestion,
  ...props
}) {

  const isLock = useSelector((state) => state?.isChatPending?.isLock);
  const [value, setValue] = useState("");
  const { isMobile } = useScreenSize();
  const [recording, setRecording] = useState(false);
  let isMutipleChoice = typeQuestion === "multiple_choice";

  // Waveform
  const [audioStream, setAudioStream] = useState(null); // Lưu trữ stream từ MicRecord

  const handleFormSubmit = async (valueToSubmit) => {
    if (!valueToSubmit && !valueOptions) return; // Thoát nếu không có giá trị cần gửi
  
    try {
      const submissionValue = isMutipleChoice ? valueOptions : valueToSubmit || value;
      const dataResponse = await handleSubmitQuestion(submissionValue);
  
      if (dataResponse) {
        setValue(""); // Reset ô nhập nếu API trả về kết quả
      }
    } catch (error) {
      console.error("Lỗi khi gửi câu hỏi:", error); // Xử lý lỗi từ API
    }
  };
  
  const handleChange = (e) => setValue(e.target.value);

  const handleStreamUpdate = (stream) => {
    setAudioStream(stream); // Cập nhật stream khi có thay đổi
  };

  // Handle key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Ngăn xuống dòng
      handleFormSubmit(value); // Gọi hàm submit
    }
    // Nếu Shift + Enter thì hành vi xuống dòng sẽ được giữ nguyên
  };

  // Handle Submit From STT
  const handleSubmitFromSTT = async (stringSTT) => {
    console.log(stringSTT, "stringSTT.....");
    // Submit stringSTT với handleSubmitQuestion
    handleFormSubmit(stringSTT);  // Gọi handleSubmitQuestion với stringSTT
  };

   // Hàm nhận trạng thái ghi âm từ MicRecord
   const handleRecordingStatusChange = (status) => {
    setRecording(status); // Cập nhật trạng thái ghi âm
    console.log("Recording Status:", status ? "Started" : "Stopped");
  };

  return (
    <StyledInputContainer isMobile={isMobile} typeQuestion={isMutipleChoice}>
      <StyledTextAreaWrapper>
        <TextArea
          {...props}
          value={valueOptions || value}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          isMobile={isMobile}
          disabled={disabled || isLoading || isLock || recording}
          textareaRef={textareaRef}
          placeholder={audioStream ? "" : "Enter your answer or ask me for help here..."}
          name="answer"
        />
        {audioStream &&
          <StyledAudioWaveform>
            <AudioWaveform stream={audioStream}/>
          </StyledAudioWaveform>
        }
      </StyledTextAreaWrapper>
      <ButtonGroup isMobile={isMobile}>
        {!isMutipleChoice && (
          <MicRecord
            handleSubmitFromSTT={handleSubmitFromSTT}
            onStreamUpdate={handleStreamUpdate}
            onRecordingStatusChange={handleRecordingStatusChange}
          />
        )}
        {isMobile ? (
          <CustomIcon
            {...props}
            onSubmitWIcon={() => {
              handleFormSubmit(value);
            }}
          />
        ) : (
          !isMobile &&
          !recording && (
            <ButtonSend
              {...props}
              handleSubmit={handleFormSubmit}
              isMessageLoader={isMessageLoader}
              disabled={isLoading || isLock || recording}
            />
          )
        )}
      </ButtonGroup>
    </StyledInputContainer>
  );
}

export default InputFreeText;