import React, { memo } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ButtonTaskItem } from "@components/learner/StandardScreen/CaseTask";
import { TasksMain } from ".";
import { getIconForObject } from "@helpers";
import { useScreenSize } from "@context/ScreenSizeContext";

function ActionsLearnerPrimary(props) {
    const { dataInterface, handleExamMenu, dataTour, isCurrentTask } = props;
    const listItem = dataInterface?.InteractiveItems || [];
    const fileList = dataTour?.filter(event => event?.selector?.includes("step-3")) || [];
    const isMissingTask = useSelector(state => state?.checkMissingTask?.data?.MissingTask);
    const { isMobile } = useScreenSize();

    const isDisabled = isCurrentTask === "history" && isMissingTask === undefined;

    // Tạo danh sách đã lọc cho chế độ mobile
    const filteredList = isMobile
        ? listItem.filter(item => item.Task === "chat" || item.Task === "exam")
        : listItem;

    // Hàm render một task item
    const renderTaskItem = (value, index) => {
        const iconPath = getIconForObject(value.Task);
        const label = value.Title;
        const isSingleOrTwoItems = filteredList.length === 1 || filteredList.length === 2;

        return (
            <ButtonTaskItem
                key={index}
                label={label}
                icon={iconPath}
                attributeTour={fileList?.[0]?.selector}
                handleClickItem={() => handleExamMenu(value.Task)}
                attributeTask={value.Task}
                isDisabled={isDisabled}
                isSingleOrTwoItems={isSingleOrTwoItems}
            />
        );
    };

    // Hàm render giao diện cho chế độ mobile
    const renderMobileView = () => {
        const sliderSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
        };

        const containerStyle = {
            maxWidth: "100%",
            overflow: "hidden",
            margin: "0 auto",
            paddingTop: "14px",
        };

        if (filteredList.length > 4) {
            // Hiển thị dạng slider nếu có hơn 4 task
            return (
                <div style={containerStyle}>
                    <Slider {...sliderSettings}>
                        {filteredList.map((item, index) => (
                            <div key={index}>
                                {renderTaskItem(item, index)}
                            </div>
                        ))}
                    </Slider>
                </div>
            );
        } else {
            // Hiển thị dạng grid nếu có 4 hoặc ít hơn task
            return (
                <div style={containerStyle}>
                    <div
                        style={{
                            display: "grid",
                            gap: "10px",
                            gridTemplateColumns: `repeat(${filteredList.length}, 1fr)`,
                        }}
                    >
                        {filteredList.map((item, index) => renderTaskItem(item, index))}
                    </div>
                </div>
            );
        }
    };

    // Hàm render giao diện cho chế độ desktop
    const renderDesktopView = () => (
        <React.Fragment>
            {filteredList.map(renderTaskItem)}
        </React.Fragment>
    );

    return (
        <TasksMain heading={"Case tasks"}>
            {isMobile ? renderMobileView() : renderDesktopView()}
        </TasksMain>
    );
}

export default memo(ActionsLearnerPrimary);