import React, { useState, useRef } from "react";
import { FormModal } from "@ui-partents/Modal";
import TableDataFromDB from "./TableDataFromDB";
import { toast } from "react-toastify";
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";
import { getReferenceBook } from "@actions";

const ModalImportFromDB = ({ isOpen, toggleModal, title }) => {
    const [hasSelection, setHasSelection] = useState(false);
    const [loading, setLoading] = useState(false);
    const selectedItemsRef = useRef(new Set());
    const dispatch = useDispatch();


    const reloadReferenceBookList = async (skipLoading = true) => {
        try {
            const result = await dispatch(getReferenceBook({}, skipLoading));
            if (result.data?.status === 200) {
                toggleModal(); // Đóng modal khi thành công
            } else {
                throw new Error("Failed to reload reference book list.");
            }
        } catch (error) {
            console.error("Thất bại khi tải lại danh sách:", error);
            toast.error("Failed to reload reference book list. Please try again.", {
                position: "bottom-right",
                autoClose: 4000,
            });
        }
    };    

    const handleAction = async () => {
        const SelectedItems = Array.from(selectedItemsRef.current || []);
        const params = { SelectedItems };

        setLoading(true);
        console.log("Params being sent:", params);

        try {
            const res = await apiCaller(`/api/reference/import/execute/`, "POST", params);
            if (res?.status === 200 && res?.data?.status === "success") {
                toast.success(res.data.message || "Successfully imported references!", {
                    position: "bottom-right",
                    autoClose: 4000,
                });
            } else {
                throw new Error(res?.data?.message || "An error occurred during the import.");
            }
        } catch (error) {
            console.error("Error occurred during import:", error);
            const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred.";
            toast.error(errorMessage, {
                position: "bottom-right",
                autoClose: 4000,
            });
        } finally {
        
            await reloadReferenceBookList();
            setLoading(false);
        }
    };

    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={title}
            actionText="Add to My Library"
            actionDisabled={!hasSelection || loading}
            onAction={handleAction}
            maxWidth="940px"
            isLoading={loading}
        >
            <TableDataFromDB
                setHasSelection={setHasSelection}
                selectedItemsRef={selectedItemsRef}
            />
        </FormModal>
    );
};

export default ModalImportFromDB;