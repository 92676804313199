import React from "react";

function SwitchItem(props) {
    const { 
        name, 
        label, 
        handleChangeSwitch, 
        value,
        noPadding,
        styled="switches"
    } = props;
    
    return (
        <div className={`align-items-center d-flex ${noPadding ? "" : "py-3"}`}>
            <span className="label-switches mr-2">{label}</span>
            <div className={`${styled} d-inline-flex`}>
                <input
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={handleChangeSwitch}
                    id={name}
                    readOnly
                />
                <label htmlFor={name}>
                    <span />
                </label>
            </div>
        </div>
    );
}

export default SwitchItem;