import React, { useState } from "react";
import { ModalResetCase } from '@components/common/ResetAndBack';
import { Iconsax } from "@components-common";
import { useScreenSize } from '@context/ScreenSizeContext';

function ResetCaseButton(props) {
    const { isMobile } = useScreenSize();
    const [isResetCaseOpen, setIsResetCaseOpen] = useState(false);

    const toggleResetCase = () => {
        setIsResetCaseOpen(!isResetCaseOpen);
    };

    const buttonSize = props.sizeButton ? `${props.sizeButton}px` : "52px";
    const buttonStyle = {
        background: isMobile ? "rgba(222, 245, 255, 0.33)" : "white",
        width: props.sizeButton ? buttonSize : (props.width || buttonSize),
        height: props.sizeButton ? buttonSize : (props.height || buttonSize),
        borderRadius: "50%",
        position: isMobile ? "absolute" : "static",
        right: isMobile ? "0" : "auto",
        top: isMobile ? "50%" : "auto",
        transform: isMobile ? "translateY(-50%)" : "none",
        margin: isMobile ? "unset !important" : undefined // Chỉ áp dụng khi là mobile
    };

    const iconColor = isMobile ? "white" : "#4B5565";

    return (
        <React.Fragment>
            <button
                className="btn btn-reset-case mr-2"
                style={buttonStyle}
                onClick={toggleResetCase}
            >
                <Iconsax iconName="reset" fill={iconColor} />
            </button>
            <ModalResetCase
                {...props}
                isOpen={isResetCaseOpen}
                toggle={toggleResetCase}
                isView="case"
            />
        </React.Fragment>
    );
}

export default ResetCaseButton;