import React from "react";

//Icon
import { Iconsax } from "@components-common";

function BtnReviewErm(props) {
    const { handleOpenErmViewFixed } = props && props;
    return (
        <button
            type="button"
            className="button-yellow text-1 justify-content-center p-1 mt-2"
            onClick={handleOpenErmViewFixed}
        >
            <Iconsax iconName="document" fill="#fff" size={25} />
            <span className="ml-2">Review</span>
        </button>
    );
}

export default BtnReviewErm;