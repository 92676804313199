import React from 'react';
import { useSelector } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { useScreenSize } from '@context/ScreenSizeContext';

// Component con cho nút Ready
const ReadyButton = ({ shouldShow, isMobile, isSingleOrTwoItems }) => {
    if (!shouldShow) return null;

    // Chỉ áp dụng style inline cho mobile
    let mobileReadyStyle = null;

    if (isMobile) {
        if (isSingleOrTwoItems) {
            mobileReadyStyle = { position: 'absolute', top: '-10px', right: '0' };
        } else {
            mobileReadyStyle = { position: 'absolute', top: '-10px', right: 'unset', transform: 'translateX(-50%)' };
        }
    }

    return (
        <span
            className={`tasks-nav__item--focus ${isMobile && isSingleOrTwoItems ? '-mobile' : ''}`}
            style={isMobile ? mobileReadyStyle : null} // Style inline chỉ áp dụng cho mobile
        >
            <span className="pulse"></span>
            Ready
        </span>
    );
};

function ButtonTaskItem({
    handleClickItem,
    label,
    icon,
    attributeTask,
    attributeTour,
    isDisabled,
    isOpenPopper,
    contentPopper,
    nextTour,
    isErm,
    activeMenuTask,
    dataPopper,
    isViewMessage,
    isSingleOrTwoItems
}) {
    const missingTaskData = useSelector(state => state?.checkMissingTask?.data) || {};
    const isMissingTask = missingTaskData?.MissingTask;

    const { isMobile } = useScreenSize();

    const isActive = activeMenuTask === attributeTask;
    const lockState = !isMobile && isDisabled ? "lockItem" : "unlockItem";
    const popperContent = isErm ? contentPopper : dataPopper?.[0]?.Content;
    const popperNextTour = isErm ? nextTour : dataPopper?.[0]?.nextTour;

    // Style điều chỉnh container, bổ sung height khi có 3 item trở lên
    const containerStyles = {
        pointerEvents: isActive ? 'none' : 'auto',
        ...(isMobile ? { pointerEvents: 'auto' } : {}),
        ...(isSingleOrTwoItems ? {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'start',
        } : {
            height: '78px',
            paddingTop: '15px'
        }),
    };

    // Kiểm tra trạng thái hiển thị nút Ready
    const shouldShowReady = isMissingTask === attributeTask || isViewMessage;

    return (
        <div
            className={`tasks-nav__item position-relative ${isMobile ? 'isStyleMobile' : ''} ${isActive ? "active" : ""} ${lockState} ${!isSingleOrTwoItems ? 'multi-items' : ''}`}
            data-task={attributeTask}
            data-tour={attributeTour}
            onClick={handleClickItem}
            style={containerStyles}
        >
            <div className="tasks-nav__item--icon">
                <img src={icon} alt={label} />
            </div>
            <div className="tasks-nav__item--text">
                <div dangerouslySetInnerHTML={{ __html: label }} />
            </div>

            {/* Hiển thị nút Ready */}
            <ReadyButton
                shouldShow={shouldShowReady}
                isMobile={isMobile}
                isSingleOrTwoItems={isSingleOrTwoItems}
            />

            <PopperItem
                attributeTask={attributeTask}
                attributeTour={attributeTour}
                isOpenPopper={isOpenPopper}
                styleInline={{ right: '180px', bottom: '0', position: 'absolute' }}
                position="right"
                content={popperContent}
                nextTour={popperNextTour}
            />
        </div>
    );
}

export default ButtonTaskItem;