import React, { useState } from "react";
import styled from "styled-components";

// Icon
import VitalIcon from "@images/learnerv2/vital-sp.svg";
import ErmIcon from "@images/learnerv2/erm-sp.svg";

// react-redux
import { useDispatch } from "react-redux";

// actions
import { toggleEMRForModalMobile } from "@actions";

// react-router-dom
import { useParams } from "react-router-dom";

import { PopupVitalSignMobile } from "@components/learner/StandardScreen/LearnerMobile";

const IconListContainerStyled = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: flex-start;
  z-index: 1;
  gap: 12px;
`;

const IconItemStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 6px;
  border-radius: 4px;
  background: #fff;
  min-width: 80px;
  border: 1px solid #0089C2;
  background: #fff;
  .icon-wrapper {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 3px 2px 2px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #cdf1ff;
  }

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  span {
    overflow: hidden;
    color: #121926;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
    padding-left: 4px;
  }
`;

const IconListContainer = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activePopup, setActivePopup] = useState(null);

  const handleIconClick = (popupType) => {
    setActivePopup(popupType);
  };

  const handleCloseModal = () => {
    setActivePopup(null);
  };

  return (
    <>
      <IconListContainerStyled>
        {parseInt(id) !== 754 && (
          <IconItemStyled onClick={() => handleIconClick("vitalSign")}>
            <div className="icon-wrapper">
              <img src={VitalIcon} alt="Vital Icon" />
            </div>
            <span>Vital Sign</span>
          </IconItemStyled>
        )}
        <IconItemStyled onClick={() => dispatch(toggleEMRForModalMobile(true))}>
          <div className="icon-wrapper">
            <img src={ErmIcon} alt="ERM Icon" />
          </div>
          <span>Review</span>
        </IconItemStyled>
      </IconListContainerStyled>
      {activePopup === "vitalSign" && (
        <PopupVitalSignMobile {...props} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default IconListContainer;