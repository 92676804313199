import React from "react";
import styled from "styled-components";

// context
import { useScreenSize } from "@context/ScreenSizeContext";

// Icon
import IconCase from "@images/learnerv2/case-alert.svg";

const CaseTitle = ({ title }) => {
    // Use screen size context
    const { isMobile } = useScreenSize();

    return (
        <React.Fragment>
            {!isMobile ? (
                <div className="case-title-block d-flex align-items-center">
                    <img src={IconCase} alt="case" className="case info" />
                    <h2 className="case-title-block__name ml-2">{title}</h2>
                </div>
            ) : (
                <MobileStyle>{title}</MobileStyle>
            )}
        </React.Fragment>
    );
};

export default CaseTitle;

// Styled Component for Mobile
const MobileStyle = styled.div`
    color: #121926;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    margin-bottom: 14px;
    align-items: center;
`;