import React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Iconsax } from '@components-common';

const CloseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 26px;
    top: 20px;
    background: none;
    border: none;
    cursor: pointer;
    &:hover svg {
        stroke: #17475C;
    }
`;

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const FormModal = ({
    isOpen,
    toggleModal,
    title,
    children,
    onAction,
    actionText,
    maxWidth,
    isLoading,
    actionDisabled,
    deleteButtonConfig,
    hiddenIcon,
    hiddenButtonCancel,
    classCustom,
    iconConfig // Thêm thuộc tính mới là object
}) => {
    const showFooter = !hiddenButtonCancel || onAction || (deleteButtonConfig && deleteButtonConfig.show);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            centered
            size='lg'
            className={`${classCustom} modal-custom modal-size-lg`}
            style={{ maxWidth: maxWidth }}
        >
            <ModalHeader>
                {!hiddenIcon && iconConfig?.iconName && (
                    <Iconsax iconName={iconConfig.iconName} fill={iconConfig.color || "#17475C"} size={20} />
                )}
                <p className='modal-title__text'>{title}</p>
                <CloseButton onClick={toggleModal}>
                    <CloseIcon />
                </CloseButton>
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {showFooter && (
                <ModalFooter>
                    <div className='d-flex w-100'>
                        {deleteButtonConfig?.show && (
                            <div className='w-50'>
                                <Button
                                    color="danger"
                                    className="button-cta button-sm"
                                    onClick={deleteButtonConfig.onAction}
                                    disabled={deleteButtonConfig.disabled || isLoading}
                                >
                                    {deleteButtonConfig.isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                    {deleteButtonConfig.text || "Delete"}
                                </Button>
                            </div>
                        )}
                        <div className={deleteButtonConfig?.show ? 'w-50' : 'w-100'}>
                            <div className="d-flex align-items-center justify-content-end">
                                {!hiddenButtonCancel &&
                                    <Button
                                        color="secondary"
                                        onClick={toggleModal}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </Button>
                                }
                                {onAction &&
                                    <Button
                                        color="primary"
                                        className="ml-2"
                                        onClick={onAction}
                                        disabled={actionDisabled || isLoading}
                                    >
                                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                        {actionText}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default FormModal;