import React, { useEffect } from 'react';
import styled from 'styled-components';
import topTaskIcon from '@images/learnerv2/icon-top-task-sp.svg';
import { Iconsax } from "@components-common";

//actions
import { toggleEMRForModalMobile } from "@actions";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import { MedicalInfo } from ".";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100dvh;
  background-color: #fff;
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.hasQuestion && props.isOpen ? 14000 : 16000)};
`;

const ChatPopupWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  padding: 0 15px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  border-radius: 12px;
  position: relative;
  height: 100%;
  &:before {
    content: "";
    background-image: url(${topTaskIcon});
    width: 137px;
    height: 53px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 150px;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 10000;
  width: 40px;
  height: 40px;
`;

const NarativePopup = (props) => {

  //props
  const { isOpen } = props && props;
  const dispatch = useDispatch();
  const questionData = useSelector((state) => state.question || {});
  const QuestionResponse = questionData?.data?.Question;

  const handleClose = () => {
    dispatch(toggleEMRForModalMobile(false));
  };

  useEffect(() => {
    const disableScroll = (e) => e.preventDefault();

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", disableScroll, { passive: false });
    } else {
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", disableScroll);
    }

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", disableScroll);
    };
  }, [isOpen]);

  return (
    <Overlay isOpen={isOpen} hasQuestion={QuestionResponse !== null}>
      <CloseButton onClick={handleClose}>
        <Iconsax iconName="close" fill="#9AA4B2" iconSize={25} />
      </CloseButton>
      <ChatPopupWrapper isOpen={isOpen}>
        <InnerWrapper>
          <MedicalInfo 
            {...props}
          />
        </InnerWrapper>
      </ChatPopupWrapper>
    </Overlay>
  );
};

export default NarativePopup;