import React from "react";

//components
import { ButtonCloseERM } from "../StandardScreen/ErmView";

function PatientMainOnlyHeading(props) {
    const { heading, children, stateType } = props && props;
    const filteredNames = Object.keys(stateType).filter(key => stateType[key] === true);
    const nameView = filteredNames && filteredNames[0];
    return (
        <div id={`actions-panel`} is-view="actions">
            <div className={`taskPanel-view`}>
                <div className="taskPanelCt__heading">
                    <span className={`taskPanel-view__heading--icon`}></span>
                    <h3 className="taskPanelCt__heading--name">{heading}</h3>
                    <ButtonCloseERM
                        {...props}
                        isCurrentTask={nameView}
                    />
                </div>
                <div className={`taskPanel-view__body`} style={{
                    height: 'calc(100% - 80px)',
                    overflowY: 'auto'
                }}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default PatientMainOnlyHeading;