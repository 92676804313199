import React, { useRef, useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import styled from 'styled-components'
import TitleNoteCommon from './TitleNoteCommon'
import Select from "react-select";
import { Controller } from 'react-hook-form';

// Icon
import { Icon } from '@components/common'
import upload from '@images/teachV2/upload-cloud.svg'

//utils
import { listChatGpt, listFileAccept } from "@utils";

//components
import { UploadChooseReferenceLibrary, ModalListBook, BookListFile, DataFileImported, ComponentChooseMedia } from "../generate";
import ModalListMedia from './ModalListMedia';
import MediaListFile from './MediaListFile';

const WrapForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  .form-option,
  .form-upload {
    width: 100%;
    .wrap-option {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .wrap__select {
        .react-select__control {
          border-radius: 8px;
          box-shadow: none;
        }
        .react-select__indicator {
          padding: 10px 14px;
        }
        .react-select__value-container {
          padding-left: 12px;
        }
      }
      &__checkbox {
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 8px;
        background: #FFF;
        gap: 12px;
        input[type="checkbox"] {
          -webkit-appearance: none;
          appearance: none;
          background: #fff;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          border: 1px solid #D0D5DD;
          display: grid;
          place-content: center;
          cursor: pointer;
        }
        input[type="checkbox"]:checked {
          background: #0089C2;
        }
        input[type="checkbox"]::before {
          content: "";
          width: 10px;
          height: 10px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em #fff;
        }
        input[type="checkbox"]:checked::before {
          transform: scale(1);
          transform-origin: bottom left;
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }
        .form-check-input {
          margin: 0;
          position: relative;
        }
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
      &__upload {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        border-radius: 12px;
        background: #FFF;
        &-input {
          width: 100%;
          padding: 12px 0;
          cursor: pointer;
          border-radius: 4px;
          border: 1px dashed #D0D5DD;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 4px;
          border-radius: 12px;
        }
        &-choose {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .wrap-choice {
            gap: 12px;
            &__or {
              hr {
                height: 1px;
                width: 100%;
                background: #D0D5DD;
                border: none;
                margin: 0;
              }
              p {
                padding: 0 12px;
                color: #081B2A;
                font-size: 12px;
                font-weight: 400;
              }
            }
            &__btn {
              padding: 8px 14px;
              gap: 4px;
              border-radius: 8px;
              background: #F2F4F7;
              cursor: pointer;
              span {
                color: #0089C2;
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        &-icon {
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #EAECF0;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &-text {
          text-align: center;
          p {
            color: #475467;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            span {
              color: #0089C2;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
            }
          }
        }
      }
      &__file {
        &-wrap {
          padding: 16px;
          border-radius: 12px;
          background: #FFF;
          display: flex;
          align-items: start;
          gap: 8px;
          border: 1px solid #EAECF0;
        }
        &-info {
          width: 100%;
          display: flex;
          align-items: start;
          gap: 8px;
          h4 {
            color: #344054;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
          p {
            color: #475467;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          .italic {
            font-style: italic;
          }
          .progress-loading {
            text-align: left;
          }
          .btn-try {
            cursor: pointer;
            color: #475467;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
        }
        &-trash {
          cursor: pointer;
          &:hover {
            svg {
              path {
                stroke: red;
              }
            }
          }
        }
      }
      .wrap-radio {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .form-check-label {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #081B2A;
          font-size: 16px;
          font-weight: 500;
          .form-check-input {
            width: 16px;
            height: 16px;
            position: relative;
            margin-top: 0;
            margin-left: 0;
            pointer-events: none;
          }
        }
        &__choose {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }
`

const GenerateOption = (props) => {
  const { dataFileImported, setDataFileImported, bookId, setBookId, mediaId, setMediaId, control, setValue, watch, listTemplate, handleCoin, slides, isMedia } = props;
  const hiddenFileInputText = useRef(null);
  const [showModalBooks, setShowModalBooks] = useState(false);
  const [isType, setIsType] = useState('');
  const [modal, setModal] = useState({
    modalBooks: false,
    modalMedia: false
  });

  //toggle
  const toggle = (type, value = null) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  // const Option = ({ innerProps, label, data, value }) => {
  //   return (
  //     <div
  //       {...innerProps}
  //       className='option-detail'
  //       style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
  //       <span className="react-select-item">{label}</span>
  //       {listPopular?.includes(label) && <div>
  //         <Icon src={popular} />
  //       </div>}
  //     </div>
  //   );
  // };

  const handleClick = event => {
    hiddenFileInputText.current.click();
  };

  const handleDrag = function (e) {
    e.preventDefault();
  }

  const handleChange = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    setDataFileImported(type === "change" ? event.target.files[0] : event.dataTransfer.files[0])
  };

  const handleTryAgain = async () => {
    await setDataFileImported(null)
    await hiddenFileInputText.current.click();
  }

  return (
    <WrapForm>
      <div className='form-option w-100'>
        <Row>
          <Col md={12}>
            <div className='wrap-option third-step'>
              <TitleNoteCommon 
                dataFor={'template'} 
                title={'Template'} 
                content={slides ? "Select a template for generation." : 'Choose a template from the drop-down menu that best fits your educational needs.'} 
              />
              {listTemplate?.length ? (
                <Controller
                  control={control}
                  name="template_id"
                  defaultValue={listTemplate?.[listTemplate?.length - 1]?.value}
                  render={({ field: { onChange } }) => (
                    <>
                      <Select
                        className="react-select-common w-100"
                        placeholder="Select Modal"
                        value={listTemplate?.find((c) => c.value === watch("template_id"))}
                        options={listTemplate}
                        onChange={val => {
                          setValue("template_id", val.value)
                          onChange(val.value)
                          handleCoin(val.value)
                        }}
                        classNamePrefix="react-select-custom"
                        components={{
                          IndicatorSeparator: () => null,
                          // Option: Option
                        }}
                      />
                    </>
                  )}
                />
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col md={0} className='d-none'>
            <div className='wrap-option'>
              <TitleNoteCommon dataFor={'model'} title={'Model'} content={'Select the AI model used for generation.'} />
              <Controller
                control={control}
                name="gpt_model"
                defaultValue={"gpt-4o"}
                render={({ field: { onChange } }) => (
                  <>
                    <Select
                      className="react-select-common w-100"
                      placeholder="Select model"
                      value={listChatGpt.find((c) => c.value === watch("gpt_model"))}
                      options={listChatGpt}
                      onChange={val => {
                        setValue("gpt_model", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select-custom"
                      components={{
                        IndicatorSeparator: () => null,
                        // Option: Option
                      }}
                    />
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </div>
      {isMedia && (
        <div className='form-option'>
          <div className="wrap-option">
            <TitleNoteCommon dataFor={'media'} title={'Media'} content={'Select this option to let the AI find and attach relevant media from your Media Library to each generated question'} />
            {/* <Controller
              name="media"
              control={control}
              render={({ field }) => (
                <div className="wrap-option__checkbox">
                  <Input
                    type="checkbox"
                    name='media'
                    {...field}
                  />
                  <span>Include relevant media in the results</span>
                </div>
              )}
            /> */}
            <div className='wrap-radio'>
              <div className="wrap-radio__choose">
                <Label check onClick={(e) => {
                  e.preventDefault();
                  setValue("use_media", "False")
                  if (isType === 'own-media') {
                    setIsType(null)
                    setMediaId(null)
                  } else {
                    setIsType("own-media")
                  }
                }}>
                  <Input
                    type="radio"
                    name="type"
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                    checked={isType === 'own-media' ? true : false}
                  />{" "}
                  Choose your own media from Media Library
                </Label>
                {isType === 'own-media' && (
                  mediaId ? (
                    <MediaListFile
                      mediaId={mediaId}
                      setMediaId={setMediaId}
                    />
                  ) : (
                    <div>
                      <ComponentChooseMedia openModalMedia={() => toggle("modalMedia")}/>
                    </div>
                  )
                )}
              </div>
              <div>
                <Label check onClick={(e) => {
                  e.preventDefault();
                  setMediaId(null)
                  if (isType === 'relevant-media') {
                    setIsType(null)
                    setValue("use_media", "False")
                  } else {
                    setIsType("relevant-media")
                    setValue("use_media", "True")
                  }
                }}>
                  <Input
                    type="radio"
                    name="type"
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                    checked={isType === 'relevant-media' ? true : false}
                  />{" "}
                  Let us choose relevant media from your Media Library
                </Label>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='form-upload w-100'>
        <div className='wrap-option four-step'>
          <TitleNoteCommon dataFor={'document'} title={'Attach a Document'} isUpload={true} width={350}/>
          {!dataFileImported && (!bookId || bookId.length === 0) ? (
            <div className={'wrap-option__upload'}>
              <UploadChooseReferenceLibrary
                {...props}
                setShowModalBooks={setShowModalBooks}
                showModalBooks={showModalBooks}
              />
              <div className={'wrap-option__upload-input'} onDragOver={handleDrag} onDrop={(e) => handleChange(e, "drop")} onClick={handleClick}>
                <input
                  type="file"
                  onChange={(e) => handleChange(e, "change")}
                  ref={hiddenFileInputText}
                  name="text"
                  style={{ display: "none" }}
                />
                <div className='wrap-option__upload-icon'>
                  <Icon src={upload} />
                </div>
                <div className='wrap-option__upload-text'>
                  <p className="list__input-item--note"><span>Click to upload</span> or drag and drop your file here</p>
                  <p className="list__input-item--note">PDF, PPT, Word, TXT. Max: 10 MB.</p>
                </div>
              </div>
              {/* <ChoiceNote /> */}
            </div>
          ) : (
            <React.Fragment>
              {bookId?.length > 0 ? (
                <BookListFile
                  {...props}
                  bookId={bookId}
                  setDataFileImported={setDataFileImported}
                  setBookId={setBookId}
                />
              ) : (
                <DataFileImported
                  {...props}
                  listFileAccept={listFileAccept}
                  dataFileImported={dataFileImported}
                  handleTryAgain={handleTryAgain}
                  setDataFileImported={setDataFileImported}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <ModalListBook
        isOpen={showModalBooks}
        toggle={() => setShowModalBooks(!showModalBooks)}
        setBookId={setBookId}
        bookId={bookId}
      />
      <ModalListMedia
        isOpen={modal.modalMedia}
        toggle={() => toggle("modalMedia")}
        setMediaId={setMediaId}
        mediaId={mediaId}
      />
    </WrapForm>
  )
}

export default GenerateOption